<template>
  <div class="hide">
    <!-- 高德定位 -->
    <el-amap vid="amap" :plugin="map.plugin" :center="map.center"></el-amap>

  </div>
</template>

<script>
import { BaiduMap, BmScale, BmGeolocation } from 'vue-baidu-map'
export default {
  name: 'Position',
  components: {
    BaiduMap,
    BmGeolocation,
    BmScale
  },
  data () {
    const vm = this;
    return {
      baidu: false,
      // 定图定位
      map: {
        center: [1,1],
        lng: 0, // 手机定位经纬度
        lat: 0,
        city: {
          name: '',
          code: '',
        },
        plugin: [{
          enableHighAccuracy: true,//是否使用高精度定位，默认:true
          timeout: 100,            //超过10秒后停止定位，默认：无穷大
          maximumAge: 0,           //定位结果缓存0毫秒，默认：0
          convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true,        //显示定位按钮，默认：true
          buttonPosition: 'RB',    //定位按钮停靠位置，默认：'LB'，左下角
          showMarker: true,        //定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true,        //定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true,     //定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy:true,     //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
          extensions:'all',
          pName: 'Geolocation',
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                console.log(result)
                if (result && result.position) {
                  vm.map.lng = result.position.lng;
                  vm.map.lat = result.position.lat;
                  if(result.addressComponent.city){
                    // 普通省
                    vm.map.city = {
                      name: result.addressComponent.city,
                      code: result.addressComponent.adcode.substring(0, 4),
                    }
                  }else{
                    // 直辖市
                    vm.map.city = {
                      name: result.addressComponent.province,
                      code: result.addressComponent.adcode.substring(0, 4),
                    }
                  }
                  vm.initCity();
                }else{
                  // 默认北京
                  vm.map.lng = '';
                  vm.map.lat = '';
                  vm.map.city = {
                    name: '北京市',
                    code: '',
                  }
                  vm.initCity();
                  // vm.baidu = true;
                }
              });

            }
          }
        }],
      },

    }
  },
  mounted () {
    if(this.$utils.isWeixin()){
      // 微信环境
    }
  },
  methods: {
    // 腾讯地图
    tengxunRead () {
      const key = 'U2RBZ-IDK34-K5HUI-XDO33-X5WVS-WGFED';

    },
    initCity () {
      this.$emit('change', {
        lng: this.map.lng,
        lat: this.map.lat,
        name: this.map.city.name,
        code: this.map.city.code
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.hide{
  display: none;
}
</style>